import axios from "axios";
import { API_URL } from "../config";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const USER_READ_REQUEST = "USER_READ_REQUEST";
export const USER_READ_SUCCESS = "USER_READ_SUCCESS";
export const USER_READ_FAIL = "USER_READ_FAIL";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";

export const register =
  (
    firstName,
    lastName,
    email,
    phone,
    password,
    country,
    state,
    city,
    address,
    callbacks = {}
  ) =>
  async (dispatch) => {
    const { onSuccess, onError } = callbacks;

    try {
      dispatch({ type: USER_REGISTER_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_URL}register`,
        {
          firstName,
          lastName,
          email,
          phone,
          password,
          country,
          state,
          city,
          address,
        },
        config
      );

      if (data.status === false) {
        dispatch({ type: USER_REGISTER_FAIL, payload: data.message });
        if (onError) onError(data.message);
      } else {
        dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
        if (onSuccess) onSuccess(data);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      if (onError) onError(error.message);
    }
  };

export const login =
  (email, password, callbacks = {}) =>
  async (dispatch) => {
    const { onSuccess, onError } = callbacks;

    try {
      dispatch({ type: USER_LOGIN_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_URL}login`,
        { email, password },
        config
      );
      console.log("data", data);
      if (data.status === "success") {
        dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
        // Save user info and token to local storage
        localStorage.setItem("token", data.user.token);
        localStorage.setItem("email", data.user.local.email);
        localStorage.setItem("userId", data.user._id);
        if (onSuccess) onSuccess(data);
      } else {
        dispatch({ type: USER_LOGIN_FAIL, payload: data.message });
        if (onError) onError(data.message);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      if (onError) onError(error.message);
    }
  };

export const forgotPassword =
  (email, callbacks = {}) =>
  async (dispatch) => {
    const { onSuccess, onError } = callbacks;

    try {
      dispatch({ type: FORGOT_PASSWORD_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_URL}forgot-password`,
        { email },
        config
      );

      if (data.status === "success") {
        dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data });
        if (onSuccess) onSuccess(data);
      } else {
        dispatch({ type: FORGOT_PASSWORD_FAIL, payload: data.message });
        if (onError) onError(data.message);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: FORGOT_PASSWORD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      if (onError) onError(error.message);
    }
  };

export const resetPassword =
  (userId, token, password, callbacks = {}) =>
  async (dispatch, getState) => {
    const { onSuccess, onError } = callbacks;

    try {
      dispatch({ type: RESET_PASSWORD_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const { data } = await axios.post(
        `${API_URL}reset-password`,
        { userId, token, password },
        config
      );

      if (data.status) {
        dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data });
        if (onSuccess) onSuccess(data);
      } else {
        dispatch({ type: RESET_PASSWORD_FAIL, payload: data.message });
        if (onError) onError(data.message);
      }
    } catch (error) {
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      if (onError) onError(error.message);
    }
  };

export const changePassword =
  (oldPassword, newPassword, callbacks = {}) =>
  async (dispatch, getState) => {
    const { onSuccess, onError } = callbacks;

    try {
      dispatch({ type: CHANGE_PASSWORD_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const { data } = await axios.post(
        `${API_URL}change_password`,
        { oldPassword, newPassword },
        config
      );

      if (data.status) {
        dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: data });
        if (onSuccess) onSuccess(data);
      } else {
        dispatch({ type: CHANGE_PASSWORD_FAIL, payload: data.message });
        if (onError) onError(data.message);
      }
    } catch (error) {
      dispatch({
        type: CHANGE_PASSWORD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      if (onError) onError(error.message);
    }
  };

export const updateUserProfile =
  (profileData, callbacks = {}) =>
  async (dispatch) => {
    const { onSuccess, onError } = callbacks;

    try {
      dispatch({ type: USER_UPDATE_PROFILE_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const { data } = await axios.patch(
        `${API_URL}user/update`,
        profileData,
        config
      );
      console.log("Response data: ", data);

      if (data.status) {
        dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data.result });
        console.log(
          "Profile updated successfully, triggering onSuccess callback"
        );
        if (onSuccess) onSuccess(data.result); // Ensure this is being called
      } else {
        console.log("Profile update failed, triggering onError callback");
        dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: data.message });
        if (onError) onError(data.message); // Ensure this is being called
      }
    } catch (error) {
      console.log("error.response.data.message", error.message);
      dispatch({
        type: USER_UPDATE_PROFILE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      console.log("An error occurred, triggering onError callback");
      if (onError)
        onError(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
    }
  };

export const logout = () => (dispatch) => {
  localStorage.removeItem("token");
  dispatch({ type: USER_LOGOUT });
};

export const userRead =
  (callbacks = {}) =>
  async (dispatch) => {
    const { onSuccess, onError } = callbacks;

    try {
      dispatch({ type: USER_READ_REQUEST });

      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };

      const { data } = await axios.get(`${API_URL}user/read`, config);

      if (data.status === false) {
        dispatch({ type: USER_READ_FAIL, payload: data.message });
        if (onError) onError(data.message);
      } else {
        dispatch({ type: USER_READ_SUCCESS, payload: data });
        if (onSuccess) onSuccess(data.result);
      }
    } catch (error) {
      console.log("error", error);
      dispatch({
        type: USER_READ_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      if (onError) onError(error.message);
    }
  };
