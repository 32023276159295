import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const LazyHomePage = lazy(() => import("./pages/HomePage"));
const LazyLogin = lazy(() => import("./pages/auth/Login"));
const LazySignup = lazy(() => import("./pages/auth/Signup"));
const LazyForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const LazyResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const LazyShopingCart = lazy(() => import("./pages/ShoppingCart"));
const LazyCheckout = lazy(() => import("./pages/Checkout"));
const LazyBrandHeroes = lazy(() => import("./pages/BrandHeroes"));
const LazyBlog = lazy(() => import("./pages/Blog"));
const LazyBlogDetails = lazy(() => import("./pages/BlogDetails"));
const LazyAboutus = lazy(() => import("./pages/Aboutus"));
const LazyCameras = lazy(() => import("./pages/Cameras"));
const LazyCamerasDetails = lazy(() => import("./pages/CamerasDetails"));
const LazyContactUs = lazy(() => import("./pages/Contactus"));
const LazyPrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const LazyTermsCondition = lazy(() => import("./pages/TermsCondition"));
const LazyFaq = lazy(() => import("./pages/Faq"));
const LazyWorldOfFitSpark = lazy(() => import("./pages/WorldOfFitSpark"));
const LazySnowBoarding = lazy(() => import("./pages/SnowBoarding"));
const LazyAccessories = lazy(() => import("./pages/Accessories"));
const LazyDownload = lazy(() => import("./pages/Download"));
const LazyMyAccount = lazy(() => import("./pages/MyAccount"));
const LazyOrder = lazy(() => import("./pages/OrderList"));
const LazyChangePassword = lazy(() => import("./pages/ChangePassword"));
const LazyMyAddress = lazy(() => import("./pages/MyAddress"));
const LazyCareer = lazy(() => import("./pages/career"));
const LazyCareerDetails = lazy(() => import("./pages/careerDetails"));
const LazyLifestyle = lazy(() => import("./pages/Lifestyle"));
const LazyNotFound = lazy(() => import("./pages/NotFound"));

const stripePromise = loadStripe(
  "pk_test_51OKeCSBzzADpnetIB6hBXufWws8tIwnyuWGkkGvfzHwA1oyz91SUk3UqgTbqZ6BEtxkvpQu3BuymeZ6PKLOT7vwd00S5ZBO9lo"
);

function App() {
  return (
    // <HomePage />
    <BrowserRouter>
      <Routes>
        {/* <Route path="*" element={<NoPage />} /> */}
        <Route
          path="/login"
          element={
            <Suspense fallback={<div></div>}>
              <LazyLogin />
            </Suspense>
          }
        />
        <Route
          path="/register"
          element={
            <Suspense fallback={<div></div>}>
              <LazySignup />
            </Suspense>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <Suspense fallback={<div></div>}>
              <LazyForgotPassword />
            </Suspense>
          }
        />
        <Route
          path="/reset-password/:userId/:token"
          element={
            <Suspense fallback={<div></div>}>
              <LazyResetPassword />
            </Suspense>
          }
        />
        <Route
          path="/"
          element={
            <Suspense fallback={<div></div>}>
              <LazyHomePage />
            </Suspense>
          }
        />
        <Route
          path="/shoping-cart"
          element={
            <Suspense fallback={<div></div>}>
              <LazyShopingCart />
            </Suspense>
          }
        />
        <Route
          path="/checkout"
          element={
            // <PrivateRoute>
            <Suspense fallback={<div></div>}>
              <Elements stripe={stripePromise}>
                <LazyCheckout />
              </Elements>
            </Suspense>
            // </PrivateRoute>
          }
        />
        <Route
          path="/brand-heroes"
          element={
            <Suspense fallback={<div></div>}>
              <LazyBrandHeroes />
            </Suspense>
          }
        />
        <Route
          path="/blog"
          element={
            <Suspense fallback={<div></div>}>
              <LazyBlog />
            </Suspense>
          }
        />
        <Route
          path="/blog/:slug"
          element={
            <Suspense fallback={<div></div>}>
              <LazyBlogDetails />
            </Suspense>
          }
        />
        <Route
          path="/about_us"
          element={
            <Suspense fallback={<div></div>}>
              <LazyAboutus />
            </Suspense>
          }
        />
        <Route
          path="/career"
          element={
            <Suspense fallback={<div></div>}>
              <LazyCareer />
            </Suspense>
          }
        />
        <Route
          path="/career/:id"
          element={
            <Suspense fallback={<div></div>}>
              <LazyCareerDetails />
            </Suspense>
          }
        />
        <Route
          path="/life-style-gear/:slug"
          element={
            <Suspense fallback={<div></div>}>
              <LazyLifestyle />
            </Suspense>
          }
        />
        <Route
          path="/cameras"
          element={
            <Suspense fallback={<div></div>}>
              <LazyCameras />
            </Suspense>
          }
        />
        <Route
          path="/cameras/:slug"
          element={
            <Suspense fallback={<div></div>}>
              <LazyCamerasDetails />
            </Suspense>
          }
        />
        <Route
          path="/accessories/:slug"
          element={
            <Suspense fallback={<div></div>}>
              <LazyCamerasDetails />
            </Suspense>
          }
        />
        <Route
          path="/contact_us"
          element={
            <Suspense fallback={<div></div>}>
              <LazyContactUs />
            </Suspense>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Suspense fallback={<div></div>}>
              <LazyPrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="/terms-condition"
          element={
            <Suspense fallback={<div></div>}>
              <LazyTermsCondition />
            </Suspense>
          }
        />
        <Route
          path="/faq"
          element={
            <Suspense fallback={<div></div>}>
              <LazyFaq />
            </Suspense>
          }
        />
        <Route
          path="/world-of-fitspark"
          element={
            <Suspense fallback={<div></div>}>
              <LazyWorldOfFitSpark />
            </Suspense>
          }
        />
        <Route
          path="/world-of-fitspark/:slug"
          element={
            <Suspense fallback={<div></div>}>
              <LazySnowBoarding />
            </Suspense>
          }
        />
        <Route
          path="/accessories"
          element={
            <Suspense fallback={<div></div>}>
              <LazyAccessories />
            </Suspense>
          }
        />
        <Route
          path="/download"
          element={
            <Suspense fallback={<div></div>}>
              <LazyDownload />
            </Suspense>
          }
        />
        <Route
          path="/my-account"
          element={
            <PrivateRoute>
              <Suspense fallback={<div></div>}>
                <LazyMyAccount />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/order"
          element={
            <PrivateRoute>
              <Suspense fallback={<div></div>}>
                <LazyOrder />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <PrivateRoute>
              <Suspense fallback={<div></div>}>
                <LazyChangePassword />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/my-address"
          element={
            <PrivateRoute>
              <Suspense fallback={<div></div>}>
                <LazyMyAddress />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<div></div>}>
              <LazyNotFound />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
